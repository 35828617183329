import {
    ACTIVE_MY_SLOTS_SESSION_SET,
    ADS_SLOT_CONFIRM_DIALOG_HIDE,
    ADS_SLOT_CONFIRM_DIALOG_SHOW,
    ADS_TAB_VALUE_SET,
    COMPANY_NAME_SET,
    CONTACT_PERSON_EMAIL_SET,
    CONTACT_PERSON_NAME_SET,
    CONTACT_PERSON_TWITTER_SET,
    DAY_SLOTS_STATUS_UPDATE_SET,
    EMAIL_ADDRESS_SET,
    FETCH_DAY_SESSIONS_ERROR,
    FETCH_DAY_SESSIONS_IN_PROGRESS,
    FETCH_DAY_SESSIONS_SUCCESS,
    FETCH_DAY_SLOTS_ERROR,
    FETCH_DAY_SLOTS_IN_PROGRESS,
    FETCH_DAY_SLOTS_SUCCESS,
    FETCH_MY_SESSIONS_ERROR,
    FETCH_MY_SESSIONS_IN_PROGRESS,
    FETCH_MY_SESSIONS_SUCCESS,
    FETCH_MY_SLOTS_ERROR,
    FETCH_MY_SLOTS_IN_PROGRESS,
    FETCH_MY_SLOTS_SUCCESS,
    INDUSTRY_CATEGORY_SET,
    LONG_DESCRIPTION_SET,
    MY_AD_SESSIONS_TAB_SET,
    MY_AD_SLOTS_TAB_SET,
    OVERLAY_SLOT_VALUE_SET,
    PROJECT_NAME_SET,
    PROJECT_TWITTER_SET,
    SELECTED_SLOTS_SET,
    SHORT_DESCRIPTION_SET,
    SPONSORSHIP_TYPE_SET,
    WEBSITE_URL_SET,
} from '../constants/ads';
import Axios from 'axios';
import { urlFetchMySessions, urlFetchMySlots, urlFetchSessions, urlFetchSlots } from '../constants/url';

export const setAdsTabValue = (value) => {
    return {
        type: ADS_TAB_VALUE_SET,
        value,
    };
};

export const setOverlaySlotValue = (value) => {
    return {
        type: OVERLAY_SLOT_VALUE_SET,
        value,
    };
};

export const showAdSlotConfirmDialog = (value) => {
    return {
        type: ADS_SLOT_CONFIRM_DIALOG_SHOW,
        value,
    };
};

export const hideAdSlotConfirmDialog = () => {
    return {
        type: ADS_SLOT_CONFIRM_DIALOG_HIDE,
    };
};

export const setMyAdSlotTab = (value) => {
    return {
        type: MY_AD_SLOTS_TAB_SET,
        value,
    };
};

export const setMyAdSessionsTab = (value) => {
    return {
        type: MY_AD_SESSIONS_TAB_SET,
        value,
    };
};

// sponsors registration
export const setProjectName = (value) => {
    return {
        type: PROJECT_NAME_SET,
        value,
    };
};

export const setProjectTwitter = (value) => {
    return {
        type: PROJECT_TWITTER_SET,
        value,
    };
};

export const setShortDescription = (value) => {
    return {
        type: SHORT_DESCRIPTION_SET,
        value,
    };
};

export const setLongDescription = (value) => {
    return {
        type: LONG_DESCRIPTION_SET,
        value,
    };
};

export const setCompanyName = (value) => {
    return {
        type: COMPANY_NAME_SET,
        value,
    };
};

export const setEmailAddress = (value) => {
    return {
        type: EMAIL_ADDRESS_SET,
        value,
    };
};

export const setContactPersonName = (value) => {
    return {
        type: CONTACT_PERSON_NAME_SET,
        value,
    };
};

export const setContactPersonEmail = (value) => {
    return {
        type: CONTACT_PERSON_EMAIL_SET,
        value,
    };
};

export const setContactPersonTwitter = (value) => {
    return {
        type: CONTACT_PERSON_TWITTER_SET,
        value,
    };
};

export const setWebsiteURL = (value) => {
    return {
        type: WEBSITE_URL_SET,
        value,
    };
};

export const setSponsorshipType = (value) => {
    return {
        type: SPONSORSHIP_TYPE_SET,
        value,
    };
};

export const setIndustryCategory = (value) => {
    return {
        type: INDUSTRY_CATEGORY_SET,
        value,
    };
};

export const setSelectedSlots = (value) => {
    return {
        type: SELECTED_SLOTS_SET,
        value,
    };
};

// Sessions
const fetchDaySessionsInProgress = () => {
    return {
        type: FETCH_DAY_SESSIONS_IN_PROGRESS,
    };
};

const fetchDaySessionsSuccess = (value, day) => {
    return {
        type: FETCH_DAY_SESSIONS_SUCCESS,
        value,
        day,
        variant: 'success',
    };
};

const fetchDaySessionsError = (message) => {
    return {
        type: FETCH_DAY_SESSIONS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchDaySessions = (day) => (dispatch) => {
    dispatch(fetchDaySessionsInProgress());

    const url = urlFetchSessions(day);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchDaySessionsSuccess(res.data && res.data.result, day));
        })
        .catch((error) => {
            dispatch(fetchDaySessionsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

// Slots
const fetchDaySlotsInProgress = () => {
    return {
        type: FETCH_DAY_SLOTS_IN_PROGRESS,
    };
};

const fetchDaySlotsSuccess = (value, day) => {
    return {
        type: FETCH_DAY_SLOTS_SUCCESS,
        value,
        day,
        variant: 'success',
    };
};

const fetchDaySlotsError = (message) => {
    return {
        type: FETCH_DAY_SLOTS_ERROR,
        message,
        variant: 'error',
    };
};

export const setDaySlotsStatusUpdate = (value) => {
    return {
        type: DAY_SLOTS_STATUS_UPDATE_SET,
        value,
    };
};

export const fetchDaySlots = (day) => (dispatch) => {
    dispatch(fetchDaySlotsInProgress());

    const url = urlFetchSlots(day);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchDaySlotsSuccess(res.data && res.data.result, day));
        })
        .catch((error) => {
            dispatch(fetchDaySlotsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

// Sessions
const fetchMySessionsInProgress = () => {
    return {
        type: FETCH_MY_SESSIONS_IN_PROGRESS,
    };
};

const fetchMySessionsSuccess = (value, day) => {
    return {
        type: FETCH_MY_SESSIONS_SUCCESS,
        value,
        day,
        variant: 'success',
    };
};

const fetchMySessionsError = (message) => {
    return {
        type: FETCH_MY_SESSIONS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchMySessions = (id, day) => (dispatch) => {
    dispatch(fetchMySessionsInProgress());

    const url = urlFetchMySessions(id, day);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchMySessionsSuccess(res.data && res.data.result, day));
        })
        .catch((error) => {
            dispatch(fetchMySessionsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

// Slots
const fetchMySlotsInProgress = () => {
    return {
        type: FETCH_MY_SLOTS_IN_PROGRESS,
    };
};

const fetchMySlotsSuccess = (value, day) => {
    return {
        type: FETCH_MY_SLOTS_SUCCESS,
        value,
        day,
        variant: 'success',
    };
};

const fetchMySlotsError = (message) => {
    return {
        type: FETCH_MY_SLOTS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchMySlots = (day, cb) => (dispatch) => {
    dispatch(fetchMySlotsInProgress());

    const url = urlFetchMySlots(day);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_cosmoverse_2024'),
        },
    })
        .then((res) => {
            dispatch(fetchMySlotsSuccess(res.data && res.data.result, day));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchMySlotsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

export const setActiveMySlotsSession = (value) => {
    return {
        type: ACTIVE_MY_SLOTS_SESSION_SET,
        value,
    };
};
