const URL = 'https://dev-api-c24.layerzerox.com';
export const MARKETPLACE_URL = 'https://f4.omniflix.market';

export const SIGN_IN_URL = `${URL}/login`;
export const SIGN_IN_VERIFY_URL = `${URL}/verify-login`;
export const ACCESS_TOKEN_URL = `${URL}/auth/refresh-token`;
export const CREATE_ORDER_URL = `${URL}/orders`;
export const FETCH_TICKET_TYPE_URL = `${URL}/ticket-types`;
export const urlFetchTicketTypeWithID = (ID) => `${URL}/ticket-types/${ID}`;
export const urlEligibilityCheck = (ID) => `${URL}/ticket-types/${ID}/purchase-limit`;
export const PROFILE_DETAILS_FETCH_URL = `${URL}/me/profile/details`;
export const PROFILE_IMAGE_FETCH_URL = `${URL}/me/profile/image`;
export const STRIP_SECRET_FETCH_URL = `${URL}/stripe/payment-intent`;
export const SLOT_STRIP_SECRET_FETCH_URL = `${URL}/stripe/slot-payment-intent`;
export const STATS_FETCH_URL = `${URL}/my/stats`;
export const CREATE_SLOT_ORDER_URL = `${URL}/slot-orders`;
export const CAPTCHA_VERIFY_URL = `${URL}/captcha`;
export const SUPPORT_URL = `${URL}/support-forms`;
export const urlTransferTicket = (ID) => `${URL}/my/tickets/${ID}/transfer`;

export const urlFetchTickets = (skip, limit, claimStatuses) => {
    const params = ['sortBy=created_at', 'order=desc'];
    if (claimStatuses) {
        params.push('claimStatuses[]=CLAIMED');
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/my/tickets?${params.join('&')}`;
};

export const urlFetchOrders = (skip, limit, sortBy, order) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    } else {
        params.push('sortBy=created_at');
        params.push('order=desc');
    }

    return `${URL}/my/orders?${params.join('&')}`;
};
export const urlFetchMyOrder = (id) => {
    return `${URL}/my/orders/${id}`;
};
export const urlFetchCouponCodeData = (id, type) => {
    const params = [];
    if (type) {
        params.push(`ticketType=${type}`);
        return `${URL}/coupons/${id}?${params.join('&')}`;
    }

    return `${URL}/coupons/${id}`;
};
export const urlCancelTicket = (id) => {
    return `${URL}/my/ordered-tickets/${id}/cancel`;
};
export const urlDownloadTicket = (ticketType, id) => {
    return `${URL}/my/${ticketType}/${id}/download`;
};
export const urlFetchQRCode = (id) => {
    return `${URL}/my/tickets/${id}/qr-download`;
};
export const urlClaimStatus = (id) => {
    return `${URL}/my/tickets/${id}/events/6516de614395ced659625e7e/gated-accesses/6516df9a42037af1a7d2a2c6/entry-status`;
};
export const urlClaimNFT = (id) => {
    return `${URL}/my/tickets/${id}/claim-nft`;
};
export const urlFetchTicket = (id) => `${URL}/my/tickets/${id}`;
export const urlInvoiceTicket = (id) => {
    return `${URL}/orders/${id}/invoice`;
};
export const urlSlotInvoiceTicket = (id) => {
    return `${URL}/slot-orders/${id}/invoice`;
};

// sessions
export const urlFetchSessions = (day) => `${URL}/sessions?day=${day}`;
export const urlFetchSlots = (day) => `${URL}/slots?day=${day}`;
export const urlFetchMySessions = (id, day) => `${URL}/my/sessions/${id}/slots?day=${day}`;
export const urlFetchMySlots = (day) => `${URL}/my/slots?day=${day}`;
export const urlFetchMySlotOrders = (skip, limit, sortBy, order) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    } else {
        params.push('sortBy=created_at');
        params.push('order=desc');
    }

    return `${URL}/my/slot-orders?${params.join('&')}`;
};
export const urlFetchSlotOrder = (id) => {
    return `${URL}/my/slot-orders/${id}`;
};

// slot files
export const urlUpdateOverlayFile = (sessionID, slotID) => {
    return `${URL}/my/sessions/${sessionID}/slots/${slotID}/file`;
};
export const urlUpdateInStreamFile = (sessionID) => {
    return `${URL}/my/sessions/${sessionID}/slots/file-uploads?send=true`;
};
