const variables = {
    en: {
        select_your_tickets: 'Select your tickets',
        company_project: 'Company',
        company_error: 'Company cannot be empty',
        company_and_project: 'Company / Project*',
        email_address: 'Email Address',
        email_star: 'Email*',
        email_address_error: 'Invalid Email Address',
        duplicate_email_address_error: 'Email Address should be unique',
        buy_tickets: 'Buy Tickets',
        full_name: 'Full Name',
        first_name: 'First Name*',
        last_name: 'Last Name*',
        job_title: 'Job title',
        phone_number_error: 'Phone number cannot be empty',
        country_of_residence: 'Country of Residence*',
        country_error: 'Country cannot be empty',
        nationality: 'Country of Nationality*',
        nationality_error: 'Nationality cannot be empty',
        omniflix_address_tooltip: 'Receive Cosmoverse 2024 NFTs by the team and partners to your OmniFlix Hub account',
        omniflix_address: 'OmniFlix Address for NFT Drops',
        omniflix_address_error: 'Invalid OmniFlix Address',
        name_error: 'Name cannot be empty',
        ticket: 'Ticket',
        add_tickets: 'Add Ticket',
        apply: 'Apply',
        enter_coupon_code: 'Enter Coupon Code',
        send_code: 'Send Code',
        checkout: 'Checkout',
        time_left: 'Time left',
        store_account: 'Store Account',
        verify_account: 'Verify Account',
        payment_method: 'Payment Method',
        stripe_content: 'Pay with your credit or debit card',
        pay_with_crypto: 'Pay using ATOM',
        connect_keplr_wallet: 'To pay using ATOM, connect your wallet and complete your puchase',
        support_token: 'Supported tokens: ',
        connect_wallet: 'Connect Wallet',
        accept_the: 'I accept the',
        terms: ' Terms of service,Community guidelines and Privacy Policy',
        place_order: 'Place Order',
        total: 'Total',
        connecting: 'Connecting',
        available_balance: 'Available balance',
        disconnect: 'Disconnect',
        no_coupon_added: 'No Coupon Added',
        otp_valid: 'OTP is valid for ',
        resend: 'Resend',
        verify: 'Verify',
        send_otp: 'Send OTP',
        contact_info_note: 'Note: Your email is required to send tickets and invoices. You can use this email to login and check your tickets later.',
        otp_sent: 'OTP has been sent to your email for verification. You can resend OTP only after ',

        order_summary: 'Order Summary',
        order_confirmed: 'Order Confirmed',
        order_status: 'We will send your transaction invoice\n' +
            'to your registered email',
        done: 'Done',
        view_tickets: 'View Tickets',
        tweet_text: 'Next stop: Cosmoverse 2024, Dubai! 🇦🇪 \n\n',
        tweet_text1: 'See you there.. \n\n',
        tweet_text2: '@CosmoverseHQ #OmniFlix\n\n',
        share_tweet: 'Share',
        confirmation_details: 'Confirmation Details',
        discount: 'Discount',
        payment_via: 'Payment via',
        processing: 'Processing',
        confirm: 'Confirm',
        payment_gateway_error: 'Payment gateway error. Try after some time',
        cancel: 'Cancel',

        sign_in: 'Sign In',
        tickets: 'Tickets',
        nfts: 'NFTs',
        orders: 'Orders',
        logout: 'Log Out',

        powered_by: 'Powered by',
        designed_by: 'Designed by',

        email_content: 'Sign in to access your tickets and order history',
        sent_otp: 'Sent OTP',
        otp_content: 'OTP sent to your registered Email',
        valid_email: 'Enter a valid Email Address',
        check_later: 'Transaction is in process. Check after some time.',
        approval_pending: 'Approval Pending',
        switch_account: 'Switch account in keplr and connect to update',

        order_id: 'Order ID',
        ticket_id: 'Ticket ID',
        ordered_on: 'Ordered on',
        id: 'ID',
        email: 'Email',
        name: 'Name',
        resume: 'Resume',
        select_purpose: 'Select Purpose',
        cancel_ticket: 'Cancel Ticket',
        download_ticket: 'Download e-Ticket',
        download_invoice: 'Download Invoice',
        claim: 'Claim',
        your_ticket: 'Your Ticket',
        sold_out: 'SOLD OUT',
        upcoming: 'UPCOMING',
        late_pass_content: 'Tickets will go live on ',
        edit: 'Edit',
        transfer: 'Transfer',

        // connect dialog
        cosmostation: 'Cosmostation',
        leap: 'Leap',
        keplr_wallet: 'Keplr Wallet',
        keplr: 'Keplr',
        wallet_connect: 'Wallet Connect',
        update: 'Update',
        not_enough_balance: 'Not enough balance',

        //    ads
        ads: 'Ads',
        my_ads_slots: 'My ad slots',
        day: 'Day',
        continue: 'Continue',
        continue_with: 'Continue with ',
        slots: 'slots',
        slot_id: 'Slot ID',
        overlay_slots: 'Overlay slots',
        in_stream_ads: 'Video Ad Slot',
        session: 'Session',
        available: 'available',
        selected: 'Selected',
        booked: 'Booked',
        slot_references: 'Slot REFERENCES',

        sponsor_registration: 'Sponsor Registration',
        project_name: 'Project Name',
        project_twitter: 'Project Twitter',
        write_short_description: 'Write a Short Description',
        write_long_description: 'Write a Long Description',
        company_name: 'Company Name',
        contact_person_name: 'Contact Person Name',
        contact_person_email: 'Contact Person Email Address',
        contact_person_twitter: 'Contact Person Twitter',
        website_url: 'Website URL',
        sponsorship_type: 'Sponsorship Type',
        industry_category: 'Industry/Category',
        upload_logo: 'Upload Logo',
        drag_drop: 'Drag & drop (or)',
        add_files: 'Add files',
        submit: 'Submit',

        overlay_5_sec: 'Brief 5-second ads that appear to the top right of the screen during the presentation. Perfect for a quick logo display',
        overlay_5_sec_size: 'Ad must be a png file with the dimensions 300w x 100h',
        overlay_10_sec: 'Longer 10-second ads that appear to the top-right of the screen during the presentation. Perfect for logos to be displayed',
        video_ad_slots_10_sec: 'Full-screen 10-second ads played AFTER the presentation, capturing undivided attention from the audience.',
        video_ad_slots_10_sec_size: 'Ad must be an mp4 file with dimensions 1920w x 1080h',

        // upload error messages
        upload_error1: 'Can not upload Video Ad Slot and overlays at the same time',
        upload_error2: 'Video Ad Slot should be adjacent to another slot in a session',
        upload_error3: 'Can not re-upload assets to multiple slots at a time.',
        upload_error4: 'Select slots without media. Can not select slots with media uploaded',

        // claim
        connect_with_keplr: 'Connect with Keplr',
        my_tickets: 'My Tickets',
        ordered_tickets: 'Ordered Tickets',
        message: 'Message',
        claim_nft: 'Claim NFT',
        enter_of_address: 'Enter omniflix account address here',
        invalid_address: 'Invalid Address',

        your_request_time_expired: 'Your request time is expired.',
        exit: 'Exit',
        tickets_limit: 'Tickets limit exceeded',
        eligibility_check: 'Eligibility Check',
        eligible_loyalty_pass: 'We’re glad that you’re eligible 🔥',
        not_eligible_loyalty_pass: 'Sorry! This email is not eligible',
        not_eligible_loyalty_pass1: 'Emails that registered and got their tickets scanned during both Cosmoverse 2022 & 2023 are eligible',
        not_eligible_loyalty_pass2: 'To be extra sure, cross check the email used!',
        ticket_types: 'Ticket Types',
    },
};

export default variables;
