import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import {
    COUPON_CODE_DATA_FETCH_ERROR,
    CREATE_ORDER_ERROR,
    ELIGIBILITY_CHECK_FETCH_ERROR,
    MY_ORDER_FETCH_ERROR,
    STRIP_SECRET_FETCH_ERROR,
} from '../constants/buyTickets';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS, SIGN_IN_VERIFY_ERROR, SIGN_IN_VERIFY_SUCCESS } from '../constants/signIn';
import {
    INVOICE_DOWNLOAD_ERROR,
    INVOICE_DOWNLOAD_SUCCESS,
    ORDERS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_ERROR,
    TICKET_DOWNLOAD_ERROR,
    TICKET_DOWNLOAD_SUCCESS,
    TICKETS_FETCH_ERROR,
    TRANSFER_MY_TICKET_ERROR,
    TRANSFER_MY_TICKET_SUCCESS,
    UPDATE_PROFILE_IMAGE_ERROR,
    UPDATE_PROFILE_SUCCESS,
} from '../constants/myAccount';
import {
    SLOT_ORDERS_CREATE_ERROR,
    UPDATE_IN_STREAM_FILE_ERROR,
    UPDATE_IN_STREAM_FILE_SUCCESS,
    UPDATE_OVERLAY_FILE_ERROR,
    UPDATE_OVERLAY_FILE_SUCCESS,
} from '../constants/slotOrder';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case MESSAGE_SHOW:
    case SIGN_IN_SUCCESS:
    case SIGN_IN_ERROR:
    case SIGN_IN_VERIFY_ERROR:
    case SIGN_IN_VERIFY_SUCCESS:
    case CREATE_ORDER_ERROR:
    case PROFILE_DETAILS_FETCH_ERROR:
    case MY_ORDER_FETCH_ERROR:
    case ORDERS_FETCH_ERROR:
    case COUPON_CODE_DATA_FETCH_ERROR:
    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PROFILE_IMAGE_ERROR:
    case STRIP_SECRET_FETCH_ERROR:
    case TICKET_DOWNLOAD_SUCCESS:
    case TICKET_DOWNLOAD_ERROR:
    case INVOICE_DOWNLOAD_SUCCESS:
    case INVOICE_DOWNLOAD_ERROR:
    case SLOT_ORDERS_CREATE_ERROR:
    case TICKETS_FETCH_ERROR:
    case UPDATE_IN_STREAM_FILE_SUCCESS: // upload
    case UPDATE_IN_STREAM_FILE_ERROR:
    case UPDATE_OVERLAY_FILE_SUCCESS:
    case UPDATE_OVERLAY_FILE_ERROR:
    case ELIGIBILITY_CHECK_FETCH_ERROR:
    case TRANSFER_MY_TICKET_SUCCESS:
    case TRANSFER_MY_TICKET_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
