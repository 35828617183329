import { ACCESS_TOKEN_URL, SIGN_IN_URL, SIGN_IN_VERIFY_URL } from '../constants/url';
import Axios from 'axios';
import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    EMAIL_SET,
    OTP_SET,
    SIGN_IN_DIALOG_HIDE,
    SIGN_IN_DIALOG_SHOW,
    SIGN_IN_IN_PROGRESS,
    SIGN_IN_SUCCESS,
    SIGN_IN_VERIFY_ERROR,
    SIGN_IN_VERIFY_IN_PROGRESS,
    SIGN_IN_VERIFY_SUCCESS,
} from '../constants/signIn';

export const setEmail = (value, valid) => {
    return {
        type: EMAIL_SET,
        value,
        valid,
    };
};

export const setOTP = (value) => {
    return {
        type: OTP_SET,
        value,
    };
};

export const showSignInDialog = () => {
    return {
        type: SIGN_IN_DIALOG_SHOW,
    };
};

export const hideSignInDialog = () => {
    return {
        type: SIGN_IN_DIALOG_HIDE,
    };
};

const signInInProgress = () => {
    return {
        type: SIGN_IN_IN_PROGRESS,
    };
};

const signInSuccess = (message, value) => {
    return {
        type: SIGN_IN_SUCCESS,
        message,
        value,
    };
};

const signInError = (message) => {
    return {
        type: SIGN_IN_VERIFY_ERROR,
        message,
    };
};

export const signIn = (data, cb) => (dispatch) => {
    dispatch(signInInProgress());

    Axios.post(SIGN_IN_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(signInSuccess('OTP sent', res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(signInError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const signInVerifyInProgress = () => {
    return {
        type: SIGN_IN_VERIFY_IN_PROGRESS,
    };
};

const signInVerifySuccess = (message, value) => {
    return {
        type: SIGN_IN_VERIFY_SUCCESS,
        message,
        value,
    };
};

const signInVerifyError = (message) => {
    return {
        type: SIGN_IN_VERIFY_ERROR,
        message,
    };
};

export const signInVerify = (data, cb) => (dispatch) => {
    dispatch(signInVerifyInProgress());

    Axios.post(SIGN_IN_VERIFY_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(signInVerifySuccess('Success', res.data && res.data.result['access_token']));
            localStorage.setItem('acToken_cosmoverse_2024', res.data && res.data.result['access_token']);
            localStorage.setItem('rfToken_cosmoverse_2024', res.data && res.data.result['refresh_token']);
            cb(null);
        })
        .catch((error) => {
            dispatch(signInVerifyError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const fetchAccessTokenInProgress = () => {
    return {
        type: ACCESS_TOKEN_FETCH_IN_PROGRESS,
    };
};

const fetchAccessTokenSuccess = (message, value) => {
    return {
        type: ACCESS_TOKEN_FETCH_SUCCESS,
        message,
        value,
    };
};

const fetchAccessTokenError = (message) => {
    return {
        type: ACCESS_TOKEN_FETCH_ERROR,
        message,
    };
};

export const fetchAccessToken = (cb) => (dispatch) => {
    dispatch(fetchAccessTokenInProgress());

    const data = {
        refreshToken: localStorage.getItem('rfToken_cosmoverse_2024'),
    };

    Axios.post(ACCESS_TOKEN_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            localStorage.setItem('acToken_cosmoverse_2024', res.data && res.data.result['access_token']);
            localStorage.setItem('rfToken_cosmoverse_2024', res.data && res.data.result['refresh_token']);
            dispatch(fetchAccessTokenSuccess('Success', res.data && res.data.result['access_token']));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchAccessTokenError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};
